<template>
    <div id="LogoImage" class="d-flex flex-column align-items-center">
        <v-img v-if="logoEnabled"
               :src="require(`@/assets/imgs/logoLegison.svg`)"
               alt="Logo LegisOn"
               contain
               :width="pWidthLogo"
               :max-width="pMaxWidthLogo"
               min-width="230"/>
        <v-img v-if="subtEnabled"
               :src="require(`@/assets/imgs/subtituloLegison.svg`)"
               alt="Portal de Legislação Executivo de Sergipe"
               class="mt-2"
               contain
               :width="pWidthSubt"
               :max-width="pMaxWidthSubt"
               min-width="230"/>
    </div>
</template>

<script>
    export default {
        name: 'LogoImage',
        props: {
            logoEnabled: {
                type: Boolean,
                default: true,
            },
            subtEnabled: {
                type: Boolean,
                default: true,
            },
            pWidthLogo: {
                type: String,
                required: true,
            },
            pMaxWidthLogo: {
                type: String,
                required: true,
            },
            pWidthSubt: {
                type: String,
            },
            pMaxWidthSubt: {
                type: String,
            },
        },
    }
</script>

<style>
    @import './LogoImage.css';
</style>
