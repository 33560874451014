<template>
  <header>
    <b-navbar toggleable="lg">
      <b-navbar-toggle target="nav-collapse">
        <template #default="{ expanded }">
          <v-icon v-if="!expanded">{{ iconMenuClosed }}</v-icon>
          <v-icon v-else>{{ iconMenuOpened }}</v-icon>
        </template>
      </b-navbar-toggle>

      <b-navbar-brand class="ml-auto" v-if="!home">
        <router-link to="/">
          <v-img
            :src="require(`@/assets/imgs/logoLegison.svg`)"
            alt="Logo LegisOn"
            class="my-auto"
            contain
            max-width="120"
          />
        </router-link>
      </b-navbar-brand>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="justify-content-around w-100">
          <div v-for="route in routes" :key="route.name">
            <b-nav-item active-class="active" class="nav-link" :to="route.path">
              {{ route.name }}
            </b-nav-item>
          </div>
        </b-navbar-nav>
        <AcessibilityNav />
      </b-collapse>
    </b-navbar>
  
  </header>
</template>

<script>
import { mdiBackburger } from "@mdi/js";
import { mdiMenu } from "@mdi/js";
import AcessibilityNav from "../components/AcessibilityNav.vue";
export default {
  name: "NavComponent",
  data: () => {
    return {
      theme: "",
      iconMenuClosed: mdiMenu,
      iconMenuOpened: mdiBackburger,
      routes: [
        {
          name: "Decretos Numerados",
          path: "/decreto-numerado",
        },
        {
          name: "Leis Ordinárias",
          path: "/lei-ordinaria",
        },
        {
          name: "Leis Complementares",
          path: "/lei-complementar",
        },
        {
          name: "Constituição Estadual e Emendas",
          path: "/emenda-constitucional",
        },
        {
          name: "Pesquisa Avançada",
          path: "/busca",
        },
        {
          name: "Saiba Mais",
          path: "/sobre",
        },
      ],
    };
  },
  props: ["home"],
  components: {
    AcessibilityNav,
  },
  methods: {
    toSearch: function (r) {
      window.location.href = r.path;
    },
  },
};
</script>

<style>
@import "./NavComponent.css";
</style>
