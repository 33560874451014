<template>
  <div class="d-flex">
    <v-btn
      v-for="(btn, index) in buttons"
      class="mx-0"
      fab
      small
      plain
      elevation="0"
      :key="index"
      :aria-label="btn.title"
      :title="btn.title"
      @click="auxFunction(btn.action)"
    >
      <v-icon color="accent" icon> {{ btn.icon }} </v-icon>
    </v-btn>
  </div>
</template>
<script>
import { mdiFormatFontSizeDecrease } from "@mdi/js";
import { mdiFormatFontSizeIncrease } from "@mdi/js";
import { mdiRestart } from "@mdi/js";
import { mdiHuman } from "@mdi/js";
import { mdiBrightness6 } from "@mdi/js";

export default {
  data: () => ({
    localTheme: "",
    show: false,
    dialog: false,
    zoomLevel: 100,
    zoomLevelDefault: 100,
    iconDarkTheme: mdiBrightness6,
    iconRestart: mdiRestart,
    iconHuman: mdiHuman,
    buttons: [
      {
        title: "Alterar tema",
        icon: mdiBrightness6,
        action: "toggleTheme",
      },
      {
        title: "Resetar zoom",
        icon: mdiRestart,
        action: "resetSize",
      },
      {
        title: "Mais zoom",
        icon: mdiFormatFontSizeIncrease,
        action: "zoomIn",
      },
      {
        title: "Menos zoom",
        icon: mdiFormatFontSizeDecrease,
        action: "zoomOut",
      },
    ],
  }),
  methods: {
    auxFunction: function (f) {
      this[f]();
    },
    toggleTheme: function () {
      let aux = localStorage.getItem("theme");
      this.theme = aux == "darkMode" ? "" : "darkMode";
      document.documentElement.setAttribute("data-theme", this.theme);
      localStorage.setItem("theme", this.theme);
    },
    resetSize: function () {
      this.zoomLevel = 100;
      document.body.style.zoom = `${this.zoomLevelDefault}%`;
    },
    zoomIn: function () {
      if (this.zoomLevel < 150) this.zoomLevel += 10;
      document.body.style.zoom = `${this.zoomLevel}%`;
    },
    zoomOut: function () {
      if (this.zoomLevel > 50) this.zoomLevel -= 10;
      document.body.style.zoom = `${this.zoomLevel}%`;
    }
  },
  mounted: function () {
    this.localTheme = localStorage.getItem("theme");
    document.documentElement.setAttribute("data-theme", this.localTheme);
  },
};
</script>

<style></style>
